import { grey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

export const DARK_PRIMARY_COLOR = "#1c1c1c"; // Dark background
export const DARK_SECONDARY_COLOR = grey[400];

const defaultDarkTheme = createTheme({
  palette: {
    mode: "dark", // Enables dark mode
    primary: {
      main: "#996515", // Keep primary consistent
    },
    secondary: {
      main: DARK_SECONDARY_COLOR,
    },
    background: {
      default: DARK_PRIMARY_COLOR,
      paper: "#121212",
    },
    text: {
      primary: "#ffffff",
      secondary: grey[400],
    },
    success: {
      main: "#4caf50",
    },
    warning: {
      main: "#ff9800",
    },
  },
  typography: {
    fontFamily: ["Rubik"].join(","),
    h3: {
      color: "#fff",
      fontWeight: 500,
    },
    h5: {
      color: "#fff",
      fontWeight: 500,
    },
    h6: {
      color: "#fff",
      fontWeight: 500,
      fontSize: 14,
    },
    body2: {
      fontSize: 14,
      fontWeight: 500,
      color: DARK_SECONDARY_COLOR,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          backgroundColor: "#242424",
          color: "#ffffff",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "unset",
          color: "#ffffff",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "#333",
          color: "#fff",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
      },
    },
  },
});

export default defaultDarkTheme;
