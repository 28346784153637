import {
  FaTachometerAlt,
  FaUsers,
  FaMoneyCheckAlt,
  FaPiggyBank,
  FaCogs,
  FaBook,
  FaHome,
  FaMarker,
  FaDatabase,
  FaClipboardList,
  FaLayerGroup,
  FaServer,
  FaIdCard,
  FaKey,
  FaUserAlt,
  FaUserTie
} from "react-icons/fa";

import { IRoute } from './types';

export default function useDashboardRoutes() {
  const routes: IRoute[] = [
    {
      key: "add-new",
      label: "Welcome",
      path: "/dashboard/cashier",
      icon: <FaHome />,
    },
    {
      key: "dashboard",
      label: "Dashboard",
      path: "/analytics",
      icon: <FaTachometerAlt />,
    },
    {
      key: "members",
      label: "Members",
      path: "/members",
      icon: <FaUsers />,
    },
    {
      key: "loans",
      label: "Loans",
      path: "/loans",
      icon: <FaMoneyCheckAlt />,
    },
    {
      key: "savings",
      label: "Savings",
      path: "/savings",
      icon: <FaPiggyBank />,
    },
    {
      key: "tellers",
      label: "Tellers",
      path: "/tellers",
      icon: <FaBook />,
    },
    {
      key: "markercheckers",
      label: "Marker Checker",
      path: "/markercheckers",
      icon: <FaMarker />,
    },
    {
      key: "accounting",
      label: "Accounting",
      icon: <FaDatabase />,
      children: [
        {
          key: "reports1",
          label: "Transactions",
          path: "/transactions",
          icon: <FaDatabase />, // Replaced AiTwotoneDatabase
        },
        {
          key: "create_gl",
          label: "Create JE",
          path: "/transactions/create_gl",
          icon: <FaIdCard />, // Replaced MdAddCard
        },
      ],
    },
    {
      key: "reports",
      label: "Reports",
      icon: <FaClipboardList />, // Replaced BsClipboard2DataFill
      children: [
        {
          key: "reports1",
          label: "Reports",
          path: "/reports",
          icon: <FaLayerGroup />, // Replaced TbBrandDatabricks
        },
        {
          key: "bireports",
          label: "BI Reports",
          path: "/bireports",
          icon: <FaServer />, // Replaced RiDatabaseLine
        },
      ],
    },
    {
      key: "system",
      label: "System",
      icon: <FaCogs />, // Represents system or settings
      children: [
        {
          key: "staff",
          label: "Staff",
          path: "/staff",
          icon: <FaUserTie />, // Represents staff or personnel
        },
        {
          key: "roles",
          label: "Roles",
          path: "/roles",
          icon: <FaKey />, // Represents roles or permissions
        },
        {
          key: "users",
          label: "Users",
          path: "/users",
          icon: <FaUserAlt />, // Represents individual users
        },
        {
          key: "audits",
          label: "Audits",
          path: "/audits",
          icon: <FaClipboardList />, // Represents audits or logs
        },
      ],
    }
  ];

  return { routes };
}
