import AppConfig from "../../../lib/AppConfig";
import { cleanApiError, handleResponse } from "../../../utils/handleResponse";
import { getAPIHeaders } from "../../../utils/helpers";
import { RescheduledLoan } from "../../MarkerChecker/types/responses/RescheduledLoan";
import { ConvertCreateClientResponse } from "../../Members/types/response/CreateClient";
import { CreateLoanRequest } from "../types/request/CreateLoan";
import { DataTableRequest } from "../types/request/DataTableRequest";
import { ListLoansRequest } from "../types/request/ListLoans";
import { TLoanApplicationRequest } from "../types/request/LoanApplication";
import { TApproveLoan, TDisburseLoan, TRejectLoan } from "../types/request/Loans";
import { TLoanApplicationTemplate } from "../types/response/ApplicationTemplate";
import { ClientLoan } from "../types/response/ClientLoan";
import { CreateLoanResponse } from "../types/response/CreateLoan";
import { TAPILoan } from "../types/response/GetLoan";
import { ListLoansResponse } from "../types/response/ListLoans";
import { ConvertLoanProductResponse, LoanProduct } from "../types/response/LoanProduct";
import { ConvertLoanProductsResponse, LoanProductsResponse } from "../types/response/LoanProducts";
import { ConvertLoanTemplate, LoanTemplate } from "../types/response/LoanTemplate";
import { TLoanTransactionTemplateResponse } from "../types/response/LoanTxTemplate";
import { RepaymentResponse } from "../types/response/Repayment";
import { ScoreResponse } from "../types/response/Score";

export namespace LoanRepository {
	// listLoans
	export async function listLoans(request: ListLoansRequest): Promise<ListLoansResponse> {
		// Implementation can change
		try {
			const endPoint = `/fineract-provider/api/v1/loans`;
			const pagination = `${!!request.offset ? `offset=${request.offset}` : ''}${!!request.limit ? `&limit=${request.limit}` : ''}`;
			const orderBy = `&orderBy=${request.orderBy ?? 'accountNo'}`;
			const sorttBy = `&sortBy=${request.sortBy ?? 'DESC'}`;
			let url = `${AppConfig.api_url}${endPoint}?${pagination}${orderBy}${sorttBy}`;

			let response = await fetch(url, {
				headers: getAPIHeaders()
			});

			const data = await handleResponse(response)

			return data;
		} catch (error: any) {
			console.log('Request failed');
			throw cleanApiError(error)
		}
	}

	// listLoanProducts
	export async function listLoanProducts(): Promise<LoanProductsResponse> {
		// Implementation can change
		try {
			const endPoint = `/fineract-provider/api/v1/loanproducts/template?isProductMixTemplate=true`;
			let url = `${AppConfig.api_url}${endPoint}`;

			let response = await fetch(url, {
				headers: getAPIHeaders()
			});

			const data = await handleResponse(response)

			return ConvertLoanProductsResponse.toLoanProductsResponse(data);
		} catch (error: any) {
			console.log('Request failed');
			throw cleanApiError(error)
		}
	}

	// listLoanTemplate
	export async function listLoanTemplates(): Promise<LoanTemplate> {
		// Implementation can change
		try {
			const endPoint = `/fineract-provider/api/v1/loanproducts/template`;
			let url = `${AppConfig.api_url}${endPoint}`;

			let response = await fetch(url, {
				headers: getAPIHeaders()
			});

			const data = await handleResponse(response)

			return ConvertLoanTemplate.toLoanTemplate(data);
		} catch (error: any) {
			console.log('Request failed');
			throw cleanApiError(error)
		}
	}

	// getLoanProduct
	export async function getLoanProduct(productId: string | number): Promise<LoanProduct> {
		// Implementation can change
		try {
			const endPoint = `/fineract-provider/api/v1/loanproducts/${productId}`;
			let url = `${AppConfig.api_url}${endPoint}`;

			let response = await fetch(url, {
				headers: getAPIHeaders()
			});

			const data = await handleResponse(response)

			return ConvertLoanProductResponse.toLoanProduct(data);
		} catch (error: any) {
			console.log('Request failed');
			throw cleanApiError(error)
		}
	}

	// calculateLoanRepayment
	export async function calculateLoanRepayment(request: CreateLoanRequest): Promise<RepaymentResponse> {
		// Implementation can change
		try {
			const endPoint = `/fineract-provider/api/v1/loans?command=calculateLoanSchedule`;
			let url = `${AppConfig.api_url}${endPoint}`;

			const headers = getAPIHeaders()
			headers.set('Content-Type', 'application/json')

			let response = await fetch(url, {
				method: 'POST',
				body: JSON.stringify(request),
				headers,
			});

			return await handleResponse(response)
		} catch (error: any) {
			console.log('Request failed');
			throw cleanApiError(error)
		}
	}

	// createLoan
	export async function createLoan(request: CreateLoanRequest): Promise<CreateLoanResponse> {
		// Implementation can change
		try {
			const endPoint = `/fineract-provider/api/v1/loans`;
			let url = `${AppConfig.api_url}${endPoint}`;

			const headers = getAPIHeaders()
			headers.set('Content-Type', 'application/json')

			let response = await fetch(url, {
				method: 'POST',
				body: JSON.stringify(request),
				headers,
			});

			const data = await handleResponse(response)

			return ConvertCreateClientResponse.toCreateClientResponse(data)
		} catch (error: any) {
			console.log('Request failed');
			throw cleanApiError(error)
		}
	}

	// getClientLoans
	export async function getClientLoans(clientId: string | number): Promise<ClientLoan[]> {
		// Implementation can change
		try {
			const endPoint = `/fineract-provider/api/v1/runreports/dashboard_active_loan_client_id?genericResultSet=false&R_clientId=${clientId}`;
			let url = `${AppConfig.api_url}${endPoint}`;

			let response = await fetch(url, {
				headers: getAPIHeaders()
			});

			return await handleResponse(response)
		} catch (error: any) {
			console.log('Request failed');
			throw cleanApiError(error)
		}
	}

	// getLoan
	export async function getLoan(loanId: string | number): Promise<TAPILoan> {
		// Implementation can change
		try {
			const endPoint = `/fineract-provider/api/v1/loans/${loanId}?associations=all`;
			let url = `${AppConfig.api_url}${endPoint}`;

			let response = await fetch(url, {
				headers: getAPIHeaders()
			});

			return await handleResponse(response)
		} catch (error: any) {
			console.log('Request failed');
			throw cleanApiError(error)
		}
	}

	// addNote
	export async function addNote(loanId: string | number, notes: string): Promise<void> {
		try {
			let url = `${AppConfig.api_url}/fineract-provider/api/v1/loans/${loanId}/notes`;

			const headers = getAPIHeaders()
			headers.set('Content-Type', 'application/json')

			let response = await fetch(url, {
				method: 'POST',
				body: JSON.stringify({
					"note": notes
				}),
				headers,
			});

			await handleResponse(response)

		} catch (error: any) {
			console.log('Request failed');
			throw cleanApiError(error)
		}
	}

	// updateDataTableEntry
	export async function updateDataTableEntry<T>(request: DataTableRequest<T>): Promise<void> {
		try {
			const url = `${AppConfig.api_url}/fineract-provider/api/v1/datatables/${request.dataTable}/${request.loanId}`;
			const headers = getAPIHeaders();
			headers.set("Content-Type", "application/json");

			const response = await fetch(url, {
				method: "PUT",
				body: JSON.stringify(request.data),
				headers,
			});

			await handleResponse(response);
		} catch (error: any) {
			if (error?.httpStatusCode === "404" || error?.errors?.some((err: any) => err?.userMessageGlobalisationCode === "error.msg.datatable.data.not.found")) {
				console.log("Data not found for datatable, falling back to addDataTableEntry.");
				await addDataTableEntry(request);
			} else {
				console.log("Request failed");
				throw cleanApiError(error);
			}
		}
	}

	// addDataTableEntry
	export async function addDataTableEntry<T>(request: DataTableRequest<T>): Promise<void> {
		try {
			const url = `${AppConfig.api_url}/fineract-provider/api/v1/datatables/${request.dataTable}/${request.loanId}`;
			const headers = getAPIHeaders();
			headers.set("Content-Type", "application/json");

			const response = await fetch(url, {
				method: "POST",
				body: JSON.stringify(request.data),
				headers,
			});

			await handleResponse(response);
		} catch (error: any) {
			console.log("Request failed");
			throw cleanApiError(error);
		}
	}

	// getLoanApplicationTemplate ~ retrieves loan application template
	export async function getLoanApplicationTemplate(request: TLoanApplicationRequest): Promise<TLoanApplicationTemplate> {
		try {
			const endPoint = `/fineract-provider/api/v1/loans/template?activeOnly=true`;
			const clientId = `&${request.clientId ? `clientId=${request.clientId}` : ''}`
			const productId = `&${request.productId ? `productId=${request.productId}` : ''}`
			const templateType = `&${request.templateType ? `templateType=${request.templateType}` : 'templateType=individual'}`
			let url = `${AppConfig.api_url}${endPoint}${clientId}${templateType}${productId}`;

			let response = await fetch(url, {
				headers: getAPIHeaders()
			});

			return await handleResponse(response)
		} catch (error: any) {
			throw cleanApiError(error)
		}
	}

	// approveLoan
	export async function approveLoan(loanId: number, req: TApproveLoan): Promise<any> {
		// Implementation can change
		try {
			const endPoint = `/fineract-provider/api/v1/loans/${loanId}?command=approve`;
			let url = `${AppConfig.api_url}${endPoint}`;

			const headers = getAPIHeaders()
			headers.set('Content-Type', 'application/json')

			let response = await fetch(url, {
				method: 'POST',
				headers,
				body: JSON.stringify(req)
			});

			return await handleResponse(response)
		} catch (error: any) {
			throw cleanApiError(error)
		}
	}

	// rejectLoan
	export async function rejectLoan(loanId: number, req: TRejectLoan): Promise<any> {
		// Implementation can change
		try {
			const endPoint = `/fineract-provider/api/v1/loans/${loanId}?command=reject`;
			let url = `${AppConfig.api_url}${endPoint}`;

			const headers = getAPIHeaders()
			headers.set('Content-Type', 'application/json')

			let response = await fetch(url, {
				method: 'POST',
				headers,
				body: JSON.stringify(req)
			});

			return await handleResponse(response)
		} catch (error: any) {
			throw cleanApiError(error)
		}
	}

	// getDataTableEntry
	export async function getDataTableEntry<T>(request: DataTableRequest<T>): Promise<T[]> {
		try {
			let url = `${AppConfig.api_url}/fineract-provider/api/v1/datatables/${request.dataTable}/${request.loanId}?genericResultSet=false`;

			const headers = getAPIHeaders()
			headers.set('Content-Type', 'application/json')

			let response = await fetch(url, { headers });

			const data = await handleResponse(response)

			return data
		} catch (error: any) {
			console.log('Request failed');
			throw cleanApiError(error)
		}
	}

	// getDataTableEntry
	export async function getLoanTransactionTemplate(loanId: number, command: string): Promise<TLoanTransactionTemplateResponse> {
		try {
			let url = `${AppConfig.api_url}/fineract-provider/api/v1/loans/${loanId}/transactions/template?command=${command}`;

			const headers = getAPIHeaders()
			headers.set('Content-Type', 'application/json')

			let response = await fetch(url, { headers });

			const data = await handleResponse(response)

			return data
		} catch (error: any) {
			console.log('Request failed');
			throw cleanApiError(error)
		}
	}

	// disburseLoan
	export async function disburseLoan(loanId: number, req: TDisburseLoan): Promise<any> {
		// Implementation can change
		try {
			const endPoint = `/fineract-provider/api/v1/loans/${loanId}?command=disburse`;
			let url = `${AppConfig.api_url}${endPoint}`;

			const headers = getAPIHeaders()
			headers.set('Content-Type', 'application/json')

			let response = await fetch(url, {
				method: 'POST',
				headers,
				body: JSON.stringify(req)
			});

			return await handleResponse(response)
		} catch (error: any) {
			throw cleanApiError(error)
		}
	}

	// disburseLoanToSavings
	export async function disburseLoanToSavings(loanId: number, req: TDisburseLoan): Promise<any> {
		// Implementation can change
		try {
			const endPoint = `/fineract-provider/api/v1/loans/${loanId}?command=disburseToSavings`;
			let url = `${AppConfig.api_url}${endPoint}`;

			const headers = getAPIHeaders()
			headers.set('Content-Type', 'application/json')

			let response = await fetch(url, {
				method: 'POST',
				headers,
				body: JSON.stringify(req)
			});

			return await handleResponse(response)
		} catch (error: any) {
			throw cleanApiError(error)
		}
	}

	// listRescheduleLoans
	export async function listRescheduleLoans(params: Record<string, any>): Promise<RescheduledLoan[]> {
		try {
			const queryParams = new URLSearchParams(
				Object.entries(params)
					.reduce((acc, [key, value]) => {
						if (value !== undefined && value !== null && value !== "") {
							acc[key] = String(value);
						}
						return acc;
					}, {} as Record<string, string>)
			);

			const url = `${AppConfig.api_url}/fineract-provider/api/v1/rescheduleloans?${queryParams.toString()}`;

			const response = await fetch(url, {
				headers: getAPIHeaders(),
			});

			const data = await handleResponse(response);

			return data;
		} catch (error: any) {
			throw cleanApiError(error);
		}
	}

	// getScore
	export async function getScore(uniqueIdentifier: string): Promise<ScoreResponse> {
		try {
			const url = `${AppConfig.api_url}/gmapis/get_score/${uniqueIdentifier}`;

			const response = await fetch(url, {
				headers: getAPIHeaders(),
			});

			const data = await handleResponse(response);

			return data;
		} catch (error: any) {
			throw cleanApiError(error);
		}
	}
}