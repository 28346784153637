import { Theme } from "@mui/material";
import defaultTheme from "./defaultTheme";
import defaultDarkTheme from "./defaultDarkTheme";

const themeMap: Record<string, Theme> = {
  default: defaultTheme,
  dark: defaultDarkTheme,
};

function getThemeByName(name: string): Theme {
  return themeMap[name] || defaultDarkTheme;
}

export default getThemeByName;
