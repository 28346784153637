import React, { createContext, useState, useEffect, ReactNode } from "react";
import { ThemeProvider, CssBaseline } from "@mui/material";
import getThemeByName from ".";
import secureLocalStorage from "react-secure-storage";

// Define Context Type
interface ThemeContextProps {
  themeName?: string;
  toggleTheme?: () => void;
}

// Create Context
export const CustomThemeContext = createContext<ThemeContextProps>({});

// Theme Provider Component
export const ThemeProviderWrapper = ({ children }: { children: ReactNode }) => {
  const storedTheme = (secureLocalStorage.getItem("theme") as string) ?? "dark";
  const [themeName, setThemeName] = useState<string>(storedTheme);

  useEffect(() => {
    secureLocalStorage.setItem("theme", themeName);
  }, [themeName]);

  const toggleTheme = () => {
    setThemeName((prev) => (prev === "dark" ? "default" : "dark"));
  };

  const theme = getThemeByName(themeName);

  return (
    <CustomThemeContext.Provider value={{ themeName, toggleTheme }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </CustomThemeContext.Provider>
  );
};
