import { Provider } from "react-redux";
import Router from "./router";
import { ThemeProviderWrapper } from "./theme/MyThemeProvider";
import { store } from "./redux/store";
import { HelmetProvider } from "react-helmet-async";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import SnackbarProvider from "./lib/Snackbar";
import SuccessProvider from "./lib/Success";

function App(): JSX.Element {
  return (
    <HelmetProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Provider store={store}>
          <ThemeProviderWrapper>
            <SnackbarProvider>
              <SuccessProvider>
                <Router />
              </SuccessProvider>
            </SnackbarProvider>
          </ThemeProviderWrapper>
        </Provider>
      </LocalizationProvider>
    </HelmetProvider>
  );
}

export default App;
